import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fill-height":"","fluid":""}},[_c(VForm,{ref:"form",staticStyle:{"width":"100%"},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VContainer,[_c(VRow,{attrs:{"cols":"12","justify":"center"}},[_c('div',{staticClass:"text-h3 white--text"},[_vm._v(" Manual add or remove loyality points ")])]),_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"4","md":"4"}},[_c(VTextField,{attrs:{"dense":"","dark":"","label":"Target discord id","required":"","persistent-hint":"","hint":"Required","placeholder":"Enter the target discord id","outlined":""},model:{value:(_vm.discordId),callback:function ($$v) {_vm.discordId=$$v},expression:"discordId"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"4","md":"4"}},[_c(VTextField,{attrs:{"dense":"","dark":"","label":"Amount of points to award","required":"","persistent-hint":"","hint":"Required","placeholder":"If you want to deduct points put - infront of the number","outlined":""},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"4","md":"4"}},[_c(VTextField,{attrs:{"dense":"","dark":"","label":"Reason","required":"","persistent-hint":"","hint":"Required","placeholder":"Reason for the award or deduct of loyalty points","outlined":""},model:{value:(_vm.reason),callback:function ($$v) {_vm.reason=$$v},expression:"reason"}})],1)],1),_c(VRow,{attrs:{"justify":"center"}},[_c(VBtn,{staticClass:"mr-4",attrs:{"disabled":!_vm.valid,"color":"success"},on:{"click":_vm.createManualLoyaltyRequest}},[_vm._v(" Submit ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }