import { render, staticRenderFns } from "./EditCustomerLogs.vue?vue&type=template&id=4f38ccb9&scoped=true&"
import script from "./EditCustomerLogs.vue?vue&type=script&lang=js&"
export * from "./EditCustomerLogs.vue?vue&type=script&lang=js&"
import style0 from "./EditCustomerLogs.vue?vue&type=style&index=0&id=4f38ccb9&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f38ccb9",
  null
  
)

export default component.exports