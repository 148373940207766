import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({style:(_vm.valid ? 'background-color: rgba(183, 183, 183, 0.7) !important' : ''),attrs:{"color":"success","disabled":_vm.valid}},'v-btn',attrs,false),on),[_vm._v("Book Now!")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"booking-dialog"},[_c(VCardTitle,{staticClass:"text-h5 white--text justify-center"},[_vm._v(" Booking Summary ")]),_vm._l((_vm.values),function(value,i){return _c('div',{key:i},[_c(VRow,{staticClass:"pt-1 pb-1",staticStyle:{"width":"100%"}},[_c(VCol,{staticClass:"white--text text-center",attrs:{"cols":"6"}},[_vm._v(_vm._s(value.label))]),_c(VSpacer),_c(VCol,{staticClass:"white--text text-center",attrs:{"cols":"6"}},[_vm._v(" "+_vm._s(value.value))])],1),(i !== _vm.values.length - 1)?_c(VDivider,{staticClass:"oblivion-divider"}):_vm._e()],1)}),_c(VCardActions,[_c(VBtn,{attrs:{"color":"success","disabled":_vm.bookDisabled},on:{"click":function($event){return _vm.bookCustomer(true)}}},[_vm._v(" Book ")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"grey"},on:{"click":function($event){return _vm.bookCustomer(false)}}},[_vm._v(" Cancel ")])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }