<template>
  <v-container style="position: relative" fluid>
    <v-form v-model="valid" style="width: 100%" ref="form">
      <div class="table pb-md-0 mb-10">
        <v-row
          class="white--text py-3 pl-3 sticky-row"
          style="border-bottom: 1px solid white"
        >
          <v-col cols="12" md="1" class="d-flex align-center">
            <h3>Filters:</h3>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="2"
            class="d-flex justify-center align-center"
          >
            <v-text-field
              dense
              dark
              v-model="discordId"
              outlined
              label="DiscordId"
              clearable
              hide-details="true"
            />
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="2"
            class="d-flex justify-center align-center"
          >
            <v-text-field
              dense
              dark
              v-model="runId"
              outlined
              label="RunId"
              clearable
              hide-details="true"
            />
          </v-col>
          <v-col cols="12" md="1" class="d-flex justify-center align-center">
            <v-btn color="green" @click="getSpecificLogs()">Search</v-btn>
          </v-col>
        </v-row>
      </div>
      <v-data-table
        :items="editCustomerLogs"
        :headers="headers"
        :items-per-page="10"
        class="elevation-1 frame"
        dense
      >
        <template v-slot:item.date="{ item }">
          <span>{{ formatDate(item.date) }}</span>
        </template>

        <template v-slot:item.differences="{ item }">
          <v-btn
            small
            text
            @click="toggleDetails(item.customer.id)"
            style="color: #ffffff"
          >
            {{ expandedLogs.includes(item.customer.id) ? "Hide" : "Show" }}
            Differences
          </v-btn>
          <v-expand-transition>
            <div
              v-if="expandedLogs.includes(item.customer.id)"
              class="ml-4 mt-2"
            >
              <div v-for="(value, key) in item.differences" :key="key">
                <strong>{{ key }}:</strong>
                <p>Old: {{ value.oldValue }}</p>
                <p>New: {{ value.newValue }}</p>
              </div>
            </div>
          </v-expand-transition>
        </template>
      </v-data-table>
    </v-form>
  </v-container>
</template>

<script>
import { getDateObjectFromPickerValue } from "@/shared/getDateObjectFromPickerValue";
import Vue from "vue";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export default Vue.extend({
  name: "EditCustomerLogs",
  data: () => ({
    valid: false,
    editCustomerLogs: [],
    discordId: null,
    runId: null,
    loaded: false,
    dateRules: [(v) => !!v || "Date is required"],
    headers: [
      { text: "DiscordID", value: "id" },
      { text: "Details", value: "details" },
      { text: "Date", value: "date" },
      { text: "Customer Name", value: "customer.buyer" },
      { text: "Differences", value: "differences" },
    ],
    expandedLogs: [],
  }),
  async mounted() {
    await this.fetchEditCustomerLogs();
  },
  methods: {
    async fetchEditCustomerLogs(info = {}) {
      try {
        let response = await this.$store.dispatch("getEditCustomerLogs", info);
        if (response.status === 200) {
          this.editCustomerLogs = response.data;
        } else {
          console.error("Error loading logs:", response.message);
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    },
    async getSpecificLogs() {
      let info = {
        discordId: this.discordId || undefined,
        runId: this.runId || undefined,
      };

      await this.fetchEditCustomerLogs(info);
    },
    formatDate(date) {
      return dayjs(date).tz("Europe/Berlin").format("DD.MM.YYYY HH:mm:ss");
    },
    toggleDetails(id) {
      if (this.expandedLogs.includes(id)) {
        this.expandedLogs = this.expandedLogs.filter((logId) => logId !== id);
      } else {
        this.expandedLogs.push(id);
      }
    },
  },
});
</script>

<style scoped>
.frame {
  border: 1px solid #6381b4;
  border-bottom: none;
  background: #6381b440;
  color: #ffffff;
}

::v-deep .v-data-table tr:hover {
  background-color: black !important;
  color: #ffffff;
}
</style>
