import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCol,{attrs:{"cols":"12"}},[_c(VRow,{staticStyle:{"border-bottom":"1px solid #6381b4"},attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"md":"1"}},[_c('div',{staticClass:"white--text text-sm-body-1 text-center"},[_vm._v(" "+_vm._s(_vm.attendance.date)+" ")])]),_c(VCol,{attrs:{"md":"2"}},[_c('div',{staticClass:"white--text text-sm-body-1 text-center"},[_vm._v(" "+_vm._s(_vm.attendance.typeOfBoost)+" ")])]),_c(VCol,{attrs:{"md":"2"}},[_c('div',{staticClass:"white--text text-sm-body-1 text-center",staticStyle:{"white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis"},attrs:{"title":_vm.attendance.buyerName}},[_vm._v(" "+_vm._s(_vm.attendance.buyerName)+" ")]),_c('div',{staticClass:"grey--text text--lighten-1 text-sm-subtitle-1 text-center"},[_vm._v(" Buyer ")])]),_c(VCol,{attrs:{"md":"2"}},[_c('div',{staticClass:"white--text text-sm-body-1 text-center"},[_vm._v(" "+_vm._s(_vm.formatGold(_vm.attendance.price))+" ")]),_c('div',{staticClass:"grey--text text--lighten-1 text-sm-subtitle-1 text-center"},[_vm._v(" Price ")])]),_c(VCol,{attrs:{"md":"2"}},[_c('div',{staticClass:"white--text text-sm-body-1 text-center"},[_vm._v(" "+_vm._s(_vm.attendance.cutOption)+" ")])]),_c(VCol,{attrs:{"md":"2"}},[_c('div',{staticClass:"white--text text-sm-body-1 text-center"},[_vm._v(" "+_vm._s(_vm.formatGold(_vm.attendance.yourCut))+" ")]),_c('div',{staticClass:"grey--text text--lighten-1 text-sm-subtitle-1 text-center"},[_vm._v(" Your Cut ")])]),_c(VCol,{attrs:{"md":"1"}},[(_vm.attendance.validation === 'TRUE' || _vm.attendance.validation === '1')?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"green","size":"32"}},'v-icon',attrs,false),on),[_vm._v("mdi-check")])]}}],null,false,2694204198)},[_c('span',[_vm._v("Verified")])]):_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"white","size":"32"}},'v-icon',attrs,false),on),[_vm._v("mdi-help")])]}}])},[_c('span',[_vm._v("Unverified")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }