import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"pa-0"},[_c(VForm,{ref:"form",staticStyle:{"width":"100%"},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VTabs,{attrs:{"grow":"","background-color":"transparent","hide-slider":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabsSlider),_c(VTab,{staticClass:"cuts-tab text-md-h4 text-sm-h6 white--text rounded-t-xl",staticStyle:{"width":"50%"},attrs:{"active-class":"cuts-tab-active"}},[_vm._v(" EU ")])],1),_c(VTabsItems,{staticStyle:{"background":"transparent","width":"100%"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabItem,{staticClass:"cuts-frame"},[(_vm.loaded)?_c('Cuts',{attrs:{"cuts":_vm.cuts.eu}}):_vm._e()],1)],1),_c(VContainer,{staticClass:"cuts-frame-submit"},[_c(VRow,{attrs:{"justify":"center"}},[_c(VBtn,{staticClass:"mr-4",attrs:{"disabled":!_vm.valid,"color":"success"},on:{"click":_vm.configureCuts}},[_vm._v(" Configure ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }