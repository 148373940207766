import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$store.state.isAuthenticated)?_c('div',{staticClass:"fill-height",staticStyle:{"background":"linear-gradient(#000923, #181819)","padding":"90px 30px 30px"}},[_c(VContainer,{attrs:{"fill-height":"","fluid":""}},[_c(VRow,{staticClass:"py-5 my-4"},[_c(VCol,{attrs:{"cols":"12","md":"3"}},[_c(VSheet,{staticClass:"user-display pa-4 white--text font-weight-bold"},[_c(VAvatar,{staticClass:"mb-4",attrs:{"color":"grey darken-1","size":"64"}},[(_vm.user.avatar)?_c(VImg,{attrs:{"src":_vm.avatarUrl,"alt":"John"}}):_c(VIcon,{attrs:{"dark":""}},[_vm._v("mdi-account-circle")])],1),_c('div',[_vm._v(_vm._s(_vm.user.username))]),_c('div',[_vm._v("Amount of Loyalty Points: "+_vm._s(_vm.loyaltyPoints.amount))])],1),_c(VDivider),(_vm.$store.state.roles.some(function (role) { return role === 'ACCESS'; }))?_c('view-as-booster',{staticClass:"mr-6 mt-6",staticStyle:{"width":"100%"},on:{"loadAsBooster":_vm.loadAsBooster}}):_vm._e()],1),_c(VCol,{attrs:{"cols":"12","md":"9"}},[_c('loyalty-system-history',{attrs:{"pointHistory":_vm.loyaltyPoints.history,"purchaseHistory":_vm.loyaltyPoints.purchaseHistory}})],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }