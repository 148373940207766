import { VContainer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"pa-0"},[_c(VTabs,{attrs:{"grow":"","background-color":"transparent","hide-slider":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabsSlider),_c(VTab,{staticClass:"tab text-md-h4 text-sm-h6 white--text rounded-t-xl",staticStyle:{"width":"50%"},attrs:{"active-class":"tab-active"},on:{"click":function($event){return _vm.$emit('updateCurrentSelected', true)}}},[_vm._v(" Point History ")]),_c(VTab,{staticClass:"tab text-md-h4 text-sm-h6 white--text rounded-t-xl",staticStyle:{"width":"50%"},attrs:{"active-class":"tab-active"},on:{"click":function($event){return _vm.$emit('updateCurrentSelected', false)}}},[_vm._v(" Purchase History ")])],1),_c(VTabsItems,{staticClass:"frame",staticStyle:{"width":"100%"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabItem,[_c('point-history',{attrs:{"generalInformation":_vm.pointHistory}})],1),_c(VTabItem,[_c('purchase-history',{attrs:{"generalInformation":_vm.purchaseHistory}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }