
























































import Vue from "vue";
import NameChangeLogs from "@/components/logging/NameChangeLogs.vue";
import EditRaidLogs from "@/components/logging/EditRaidLogs.vue";
import CreateRaidLogs from "@/components/logging/CreateRaidLogs.vue";
import EditCustomerLogs from "@/components/logging/EditCustomerLogs.vue";
import DeleteCustomerLogs from "@/components/logging/DeleteCustomerLogs.vue";

export default Vue.extend({
  name: "LogViewer",
  components: {
    NameChangeLogs,
    EditRaidLogs,
    CreateRaidLogs,
    EditCustomerLogs,
    DeleteCustomerLogs,
  },
  data: () => ({
    tab: null,
  }),
});
