import { VContainer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fill-height":"","fluid":""}},[_c(VTabs,{staticClass:"oblivion-tabs",attrs:{"hide-slider":"","fixed-tabs":"","background-color":"transparent"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabsSlider),_vm._l((_vm.services),function(service){return _c(VTab,{key:service.service,staticClass:"oblivion-tab pa-1",attrs:{"active-class":"oblivion-tab-active"}},[_vm._v(" "+_vm._s(service.service)+" ")])})],2),_c(VTabsItems,{staticStyle:{"background":"transparent","width":"100%"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.services),function(service){return _c(VTabItem,{key:service.service,style:(_vm.$vuetify.breakpoint.smAndDown
          ? 'max-width: 100%; align-self: center'
          : 'max-width: 75vw; align-self: center')},[_c('service',{attrs:{"banner":service.banner,"child":service.child,"bullets-points":service.bulletPoints}})],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }