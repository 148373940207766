import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"pa-5",staticStyle:{"background":"#1d2945"},attrs:{"flat":""}},[_c(VForm,{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VTextField,{attrs:{"rules":_vm.discordIdRules,"label":"DiscordID","required":"","dark":""},model:{value:(_vm.discordId),callback:function ($$v) {_vm.discordId=$$v},expression:"discordId"}}),_c(VBtn,{staticClass:"mt-2",style:((
              _vm.valid
            )
              ? 'background-color: #1976d2 !important; color: #fff !important;' 
              : 'background-color: rgba(183, 183, 183, 0.7) !important; color: rgba(0, 0, 0, 0.26) !important'),attrs:{"disabled":!_vm.valid,"color":"success"},on:{"click":_vm.viewAsBooster}},[_vm._v(" View as Member ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }