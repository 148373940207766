import { render, staticRenderFns } from "./AboutButton.vue?vue&type=template&id=672d6de0&scoped=true&"
import script from "./AboutButton.vue?vue&type=script&lang=js&"
export * from "./AboutButton.vue?vue&type=script&lang=js&"
import style0 from "./AboutButton.vue?vue&type=style&index=0&id=672d6de0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "672d6de0",
  null
  
)

export default component.exports