import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"rounded-lg",staticStyle:{"position":"relative","background-color":"#2f4975"}},[_c(VImg,{staticStyle:{"position":"relative"},attrs:{"src":require("@/assets/booking/ShiftBooking.jpg"),"min-height":"200","max-height":"200"}},[_c(VContainer,{staticStyle:{"background-color":"rgba(80, 80, 80, 0.6)","position":"absolute","width":"100%","height":"100%"},attrs:{"fill-height":""}},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{staticClass:"white--text text-h3 text-center",attrs:{"cols":"12"}},[_vm._v(" Shift Booking ")])],1)],1)],1),_c(VCardText,{staticClass:"white--text"},[_c('ul',[_c('li',[_vm._v("Book a customer")]),_c('li',[_vm._v("Check your history")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }